import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";

const Navbar = () => {
  return (
    <div className="navigation">
      <div className="navigation_logo">
        <img src={logo} alt="Logo de l'entreprise" />
      </div>
      <div className="navigation_menu">
        <ul>
          <NavLink to="/">Accueil</NavLink>
         <NavLink to="/salon">Le salon</NavLink>
         <NavLink to="/prestations">Prestations</NavLink>
         <NavLink to="/tarifs">Les tarifs</NavLink>
         <NavLink to="/contact">Contatc</NavLink>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
