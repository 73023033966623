import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TarifaireGrid from "../components/tarif/Tarifairegrid";

const Tarifs = () => {
  return (
    <div className="page">
      <div className="top"><Navbar /></div>
      <div className="middle">
      <div className="titre_page"> Tarifs</div>

      <div className="tarif">
        <TarifaireGrid />
      </div>
      </div>
      <div className="bottom">
      <Footer />
      </div>
    </div>
  );
};

export default Tarifs;
