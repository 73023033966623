import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Prestations = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page"> Prestations</div>
321
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis
          ut nostrum dignissimos reiciendis libero, consequuntur voluptas soluta
          nulla est ea, expedita consectetur error incidunt quae! Dicta
          repellat, ullam nisi cumque eaque aut dolorum quidem quos laborum
          maiores alias commodi voluptatibus. Reprehenderit tempore ullam
          tempora iusto, commodi ipsam sunt possimus? Sint! Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Laudantium sequi minima
          molestias voluptatem omnis magni dignissimos, dolorum, dicta aperiam
          amet eius quasi. Laudantium aperiam omnis magnam sit veritatis
          eligendi. Laborum soluta velit rem illum recusandae porro sit placeat,
          eum quisquam rerum. Exercitationem veritatis rerum quas voluptate
          nulla excepturi, aut cumque.
        </p>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Prestations;
