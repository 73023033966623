import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page"> Accueil</div>
        <div className="middle_container">
          <div className="mc_photos"></div>
          <div className="mc_presa">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium
          sequi minima molestias voluptatem omnis magni dignissimos, dolorum,
          dicta aperiam amet eius quasi. Laudantium aperiam omnis magnam sit
          veritatis eligendi. Laborum soluta velit rem illum recusandae porro
          sit placeat, eum quisquam rerum. Exercitationem veritatis rerum quas
          voluptate nulla excepturi, aut cumque. Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Aperiam, quia velit animi deleniti unde
          quos corrupti recusandae iure quae voluptatibus sunt. Voluptates quia,
          veniam eveniet, incidunt veritatis dolores, nihil nobis facere ea
          quaerat in eum quasi repellendus? Veritatis ex tenetur voluptatibus
          voluptates ipsa non repellendus libero quia impedit earum. Neque,
          quam! Doloremque ipsa laboriosam, alias molestiae tenetur eligendi
          commodi, beatae nam est minima quibusdam officia tempora dolores
          temporibus. Magni, excepturi sed unde necessitatibus maxime fuga autem
          corrupti! Incidunt quo, quasi nam dolorem dolore natus, ratione quia
                  </p>
        </div>
        <div className="mc_photos"></div>
      </div>
            </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
