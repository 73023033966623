import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Salon from "./pages/Salon";
import Tarifs from "./pages/Tarifs";
import Contact from "./pages/Contact";
import Prestations from "./pages/Prestations";
import "./assets/style/Style.css";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/salon" element={<Salon/>} />
    <Route path="/tarifs" element={<Tarifs/>} />
    <Route path="/contact" element={<Contact/>} />
    <Route path="/prestations" element={<Prestations/>} />
    <Route path="*" element={<Home/>} />
    
        
      </Routes>
    </BrowserRouter>
  );
};

export default App;
