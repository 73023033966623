import React from 'react';
import TarifaireCard from '../../components/tarif/Tarifairecard';
import '../../assets/style/Style.css';

const tarifs = [
  { id: 1, title: "Coupe homme", price: "15€", description: "Suppélment de 5€ Si shampoing et/ou fixations" },
  { id: 2, title: "Shampooing, coupe dame, soin, brushing, finitions", price: "29€" },
  { id: 3, title: "Shampooing, coupe, coiffage", price: "26€" },
  { id: 4, title: "Forfait, coloration, coupe, brushing, soin", price: "62€" },
  { id: 5, title: "Forfait, mèches, coupe, brushing, soin", price: "67€" },
  { id: 6, title: "Forfait, coloration, mèches, coupe, brushing, soin", price: "72€" },
  { id: 7, title: "Suppélment colorations ou mèches pour cheveux mi-long", price: "15€" },
  { id: 8, title: "Suppélment colorations ou mèches pour cheveux long", price: "20€" },
  { id: 9, title: "Forfait ombré hair", price: "80€", description: "Possibilité de combiner avec coupe et brushing" },
  { id: 10, title: "Forfait forme, coupe, mise en forme", price: "66€" },
  { id: 11, title: "Shampooing, brushing, lissage ou mise en plis", price: "19€" },
  { id: 12, title: "Chignons", price: "Sur devis" },
  { id: 13, title: "Etudiants", price: "-10%" },
  { id: 14, title: "", price: "" },
  { id: 15, title: "Coupe enfant garçon moins de 12 ans", price: "10€" },
  { id: 16, title: "Coupe fille moins de 12 ans (shampooing et séchage)", price: "19€" },
  { id: 17, title: "Taille de barbe tondeuse", price: "8€" },

  
];

const TarifaireGrid = () => {
  return (
    <div className="tarifaire-grid">
      {tarifs.map(tarif => (
        <TarifaireCard key={tarif.id} tarif={tarif} />
      ))}
    </div>
  );
};

export default TarifaireGrid;
