import React from "react";
import "../../assets/style/Style.css";

const TarifaireCard = ({ tarif }) => {
  return (
    <div className="tarifaire-card">
      <div className="tarifaire-title">
        {tarif.title}
      </div>
      <div className="tarifaire-price">
        {tarif.price}
      </div>

      <div className="tarifaire-description">
        {tarif.description}
      </div>
    </div>
  );
};

export default TarifaireCard;
