import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import img from "../assets/img/salon.jpg"

const Salon = () => {
  return (
    <div className="page">
      <div className="top"><Navbar /></div>
      <div className="middle">
      <div className="titre_page"> Le salon</div>
      <div className="mc_container"> 
      <div className="mc_img"> <img src={img}/> </div> 
      <div className="mc_txt"> 
        
      <p>
              Bienvenue à L'atelier d'Angèle, votre salon de coiffure de
              référence à Orp-Le-Grand. Niché au cœur de notre charmant village,
              notre salon est un véritable havre de paix où élégance et
              convivialité se rencontrent.
            </p>
            <p>
              Chez L'atelier d'Angèle, nous croyons que chaque visite doit être
              une expérience unique et personnalisée. Notre équipe de coiffeurs
              passionnés et expérimentés est dédiée à vous offrir des services
              de haute qualité, adaptés à vos envies et à votre style. Que vous
              souhaitiez une coupe classique, une coloration audacieuse ou un
              soin capillaire revitalisant, nous sommes à votre écoute pour
              sublimer votre chevelure.
            </p>
            <p>
              Nous utilisons des produits professionnels de marques reconnues,
              respectueux de votre santé et de l’environnement, pour garantir
              des résultats impeccables tout en prenant soin de vos cheveux.
              Notre espace chaleureux et moderne a été pensé pour votre confort,
              vous invitant à la détente dès votre arrivée.
            </p>
            <p>
              Rejoignez-nous à L'atelier d'Angèle et laissez-vous choyer par nos
              experts. Offrez-vous un moment de bien-être et repartez avec une
              coiffure qui vous ressemble et vous met en valeur.
            </p>
      </div>
      <div className="mc_img"> <img src={img}/> </div> 
      </div>
      </div>
      <div className="bottom">
      <Footer />
      </div>
    </div>
  );
};

export default Salon;
