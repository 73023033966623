import React from 'react';

const Footer = () => {
    return (
        <div>
            <h3>Le footer</h3>
            <p>N° de TVA : BE0000000</p>

            
        </div>
    );
};

export default Footer;<h3>Le footer</h3>
